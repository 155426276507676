import React from 'react';
import {Helmet} from 'react-helmet';
import Alert from 'react-s-alert';
import Header from '../../components/Header';
import ContextMenu from '../../components/ContextMenu';
import YandexMetrika from '../../components/YandexMetrika';
import Routes from '../../routes';
import classes from './App.module.scss';

export const App = () => (
  <div className={classes.Content}>
    <Helmet
      titleTemplate='%s - Aizek.Persona'
      defaultTitle='Aizek.Persona'
    />
    <Header />
    <div className={classes.Main}>
      <Routes />
    </div>
    <ContextMenu />
    <Alert
      stack={{limit: 5}}
      position='top-right'
      effect='stackslide'
      timeout={5000}
    />
    <YandexMetrika />
  </div>
);

export default App;
