import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';
import Scrollbar from '../../../components/Scrollbar';
import LocaleSwitcher from '../../../components/LocaleSwitcher';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import alert from '../../../helpers/alert';
import {AuthInfoPopup} from './AuthInfoPopup';
import classes from './Auth.module.scss';

class Auth extends Component {
  static propTypes = {
    location: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        hash: PropTypes.string.isRequired
      })
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    isAuthorizing: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    logIn: PropTypes.func.isRequired,
    checkAuth: PropTypes.func.isRequired
  };

  state = {
    username: '',
    password: '',
    hasErrors: false
  };

  _form = null;
  _infoPopupID = null;

  componentDidMount () {
    const {isAuthorizing, isAuthorized} = this.props;

    this.checkAuth(isAuthorizing, isAuthorized);
  }

  UNSAFE_componentWillReceiveProps ({isAuthorizing, isAuthorized}) {
    this.checkAuth(isAuthorizing, isAuthorized);
  }

  setFormRef = ref => {
    this._form = ref;
  };

  checkAuth = (isAuthorizing, isAuthorized) => {
    const {location, history, checkAuth} = this.props;

    const from = location.state && location.state.from
      ? location.state.from
      : {
        pathname: '/',
        search: '',
        hash: ''
      };

    if (isAuthorized) {
      setTimeout(() => {
        history.replace(from);
      }, 1);

      return;
    }

    if (isAuthorizing) {
      return;
    }

    const token = localStorage['access_token'];

    if (typeof token !== 'string' || !token.length) {
      setTimeout(() => {
        this._form.username.focus();
      });
    } else {
      checkAuth();
    }
  };

  onFieldChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value.trim(),
      hasErrors: false
    });
  };

  onSubmit = async e => {
    const {isAuthorizing, logIn} = this.props;
    const {username, password} = this.state;

    e.preventDefault();

    if (isAuthorizing) {
      return;
    }

    if (!username.length || !password.length) {
      alert.warn(localizeMessage({
        id: 'auth.errors.emptyFields'
      }));

      this.setState({
        hasErrors: true
      });

      if (!username.length) {
        this._form.username.focus();
      } else {
        this._form.password.focus();
      }

      return;
    }

    try {
      await logIn(username, password);
    } catch (error) {
      if (!error.jsonResponse) {
        alert.error(localizeMessage({
          id: 'auth.errors.serviceUnavailable'
        }));

        return;
      }

      if (error.message === 'Failed to fetch') {
        alert.warn(localizeMessage({
          id: 'errors.connectionError'
        }));

        return;
      }

      const authErrorData = this.getAuthErrorData(error);

      if (authErrorData) {
        const {errorLocaleId: id, showInfoPopup} = authErrorData;
        alert.warn(localizeMessage({id}));

        if (showInfoPopup) {
          alert.close(this._infoPopupID);
          this._infoPopupID = alert.success(<AuthInfoPopup />, {timeout: 'none'});
        }

        this.setState({
          password: '',
          hasErrors: true
        }, () => {
          this._form.username.focus();
        });

        return;
      }

      alert.warn(localizeMessage({
        id: 'errors.unknownError'
      }));
    }
  };

  getAuthErrorData = error => {
    const {error: errorName, error_description: errorDescription} = error.jsonResponse;

    if (
      (errorName === 'unauthorized' && errorDescription === 'Invalid username or password') ||
      (errorName === 'invalid_grant' && errorDescription === 'Invalid user credentials')
    ) {
      return {errorLocaleId: 'auth.errors.incorrectUsernameOrPassword', showInfoPopup: true};
    }

    if (errorName === 'invalid_grant' && errorDescription === 'No project access') {
      return {errorLocaleId: 'auth.errors.noProjectAccess', showInfoPopup: true};
    }

    if (errorName === 'invalid_grant' && ['User is disabled', 'Account disabled'].includes(errorDescription)) {
      return {errorLocaleId: 'auth.errors.userIsDisabled', showInfoPopup: false};
    }

    return null;
  };

  render () {
    const {isAuthorizing} = this.props;
    const {username, password, hasErrors} = this.state;

    return (
      <div>
        <LocalizedMessage
          id='site.title.auth'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <Scrollbar className={classes.Container}>
          <form
            ref={this.setFormRef}
            className={cx(
              classes.Form,
              {
                [classes.FormWithErrors]: hasErrors
              }
            )}
            onSubmit={this.onSubmit}
          >
            <div className={classes.LogoWrapper}>
              <LocalizedMessage
                id='site.shortName'
              >
                {localizedMessage => (
                  <img
                    src='/img/logo.png'
                    alt={localizedMessage}
                    className={classes.Logo}
                  />
                )}
              </LocalizedMessage>
            </div>
            <div className={classes.Row}>
              <div className={classes.FieldContainer}>
                <LocalizedMessage
                  id='auth.username'
                >
                  {localizedMessage => (
                    <input
                      type='text'
                      disabled={isAuthorizing}
                      name='username'
                      value={username}
                      placeholder={localizedMessage}
                      className={classes.Field}
                      onChange={e => this.onFieldChange('username', e.target.value)}
                    />
                  )}
                </LocalizedMessage>
              </div>
            </div>
            <div className={classes.Row}>
              <div className={classes.FieldContainer}>
                <LocalizedMessage
                  id='auth.password'
                >
                  {localizedMessage => (
                    <input
                      type='password'
                      disabled={isAuthorizing}
                      name='password'
                      value={password}
                      placeholder={localizedMessage}
                      className={classes.Field}
                      onChange={e => this.onFieldChange('password', e.target.value)}
                    />
                  )}
                </LocalizedMessage>
              </div>
            </div>
            <div className={classes.Row}>
              <Button
                type='submit'
                disabled={isAuthorizing}
                theme='transparent'
                className={classes.SubmitBtn}
              >
                <LocalizedMessage
                  id='base.login'
                />
              </Button>
            </div>
          </form>
          <LocaleSwitcher
            className={classes.LocaleSwitcher}
          />
          <Loader
            active={isAuthorizing}
          />
        </Scrollbar>
      </div>
    );
  }
}

export default Auth;
