const Config = {
  api: {
    host: process.env.REACT_APP_API_URL,
    authHost: process.env.REACT_APP_API_AUTH_URL || process.env.REACT_APP_API_URL,
    authClientId: process.env.REACT_APP_API_AUTH_CLIENT_ID,
    authClientSecret: process.env.REACT_APP_API_AUTH_CLIENT_SECRET,
    headers: {
      Pragma: 'no-cache',
      'Cache-control': 'no-cache',
      realm: 'ADV'
    }
  },
  appOptions: {
    eventHintsLoadingItemsLimit: 30,
    SCROLLING_SIDE_TIME_INTERVAL: 100,
    LAZY_TABLE_CELL_WIDTH: 135,
    LAZY_TABLE_CELL_HEIGHT: 37,
    LAZY_TABLE_MAX_LEFT_SIDE_WIDTH: 500,
    LAZY_TABLE_MAX_TOP_SIDE_HEIGHT: 250,
    CUSTOM_MEASURE_VERT_ID: 99997,
    CUSTOM_MEASURE_HORZ_ID: 99998,
    CUSTOM_MEASURE_INDEX_ID: 99999,
    BEELINE_CATALOG_ID: 6,
  },
  services: {
    yandexMetrikaAccount: process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT,
  },
  projectName: 'PERSONA',
};

export default Config;
