import React from 'react';
import {Link} from 'react-router-dom';
import LocalizedMessage from '../../../components/LocalizedMessage';
import classes from '../Header.module.scss';

const Logo = () => (
  <Link
    to='/'
    className={classes.Logo}
  >
    <LocalizedMessage
      id='site.shortName'
    >
      {localizedMessage => (
        <img
          src='/img/logo.png'
          alt={localizedMessage}
          className={classes.LogoImage}
        />
      )}
    </LocalizedMessage>
  </Link>
);

export default Logo;
